<template>
  <div class="searchList">
    <i-header></i-header>
    <div class="dbkbox">
      <div class="boxcont">
        <div class="dbkHeader">
          <div class="dbkcont">
            <div class="dbklogo" @click="home">
              <img src="../../assets/images/logo.png" />
            </div>
            <div class="term">
              <div class="termLeft">
                <input type="text" placeholder="" v-model="name" @keyup.enter="onSearch"/>
                <img src="../../assets/images/dbk/sousuo.png" @click="onSearch" />
              </div>
              <el-tooltip effect="light" content="大百科检索" placement="top">
                <div class="termRight" @click="toDbkSearch">
                  <img src="../../assets/images/dbk/index_bg.png" />
                  检索
                </div>
              </el-tooltip>
              
            </div>
          </div>
        </div>
        <div class="width1100">
          <div class="antistop">
            <div class="record">
              <div>
                <span v-show="!this.title == ''"
                  >搜索“{{ this.title }}”共{{ page.totalCount }}条记录</span
                >
              </div>
              <div class="overall">
                <p @click="overone" class="tadit" :class="{ cross: cross }"></p>
                <p @click="overtwo" class="liken" :class="{ fore: fore }"></p>
              </div>
            </div>
            <div class="carte">
              <ul v-for="(bar, barIndex) in barData" :key="barIndex">
                <p>{{ bar.name }}</p>
                <li
                  v-for="(arts, artsind) in bar.children"
                  :key="artsind"
                  @click="addclass(arts.id, arts.uuid)"
                  :class="artcurrent == arts.id ? 'active' : ''"
                >
                  {{ arts.name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="result wow fadeIn" data-wow-delay=".1s" v-show="result">
            <ul>
              <li
                v-for="(item, index) in resuleData"
                :key="index"
                @click="detail(item)"
              >
                <div class="resultImg">
                  <img :src="item.titleImage" v-if="item.titleImage" />
                  <div class="default" v-else></div>
                </div>
                <div class="info">
                  <a v-html="item.highlightName"></a>
                  <p class="p2 ellipsis3" v-html="item.highlightContent"></p>
                </div>
              </li>
            </ul>
          </div>
          <div class="arranage wow fadeIn" v-if="arranage">
            <waterfall :data="resuleData" :col="4">
              <template v-for="(items, indexs) in resuleData">
                <div :key="indexs" @click="detail(items)">
                  <div class="arrImg">
                    <img :src="items.titleImage" v-if="items.titleImage" />
                    <div class="default" v-else></div>
                  </div>
                  <p class="wahead">
                    <span></span><b v-html="items.highlightName"></b>
                  </p>
                  <p class="ellipsis3 watext" v-html="items.highlightContent"></p>
                </div>
              </template>
            </waterfall>
          </div>
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="page.pageSize"
            :total="page.totalCount"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import iHeader from "@/components/Header.vue";
import { getItemTree ,search} from "@/api/home";
export default {
  name: "dbkList",
  components: {
    iHeader,
  },
  data() {
    return {
      btnFlag: false,
      resuleData: [],
      barData: [],
      artcurrent: 0,
      result: true,
      arranage: false,
      cross: false,
      fore: false,
      page: {
        totalCount:0
      },
      name: "",
      pageNo: 1,
      uuid: "",
      title: "",
    };
  },
  mounted() {
    new WOW().init();
  },
  created() {
    if (this.$route.query.sortId) {
      this.uuid = this.$route.query.sortId;
    }
    if (this.$route.query.inputVal) {
      this.name = this.$route.query.inputVal;
      this.title = this.name;
    }else{
      this.name="";
    }
    this.getDbklist();
    this.getResult();
  },
  methods: {
    /*logo跳转首页*/
    home() {
      this.$router.push({
        name: "index",
      });
    },
    /*点击分类*/
    addclass(id, uuid) {
      this.artcurrent = id;
      this.uuid =  uuid;
      this.getResult();
    },
    /*纵向排列*/
    overone() {
      this.fore = false;
      this.cross = false;
      this.result = true;
      this.arranage = false;
    },
    /*瀑布流排列*/
    overtwo() {
      this.cross = true;
      this.fore = true;
      this.result = false;
      this.arranage = true;
    },
    // 搜索
    onSearch() {
      this.title = this.name;
      this.$router.push({query: { inputVal: this.name }});
      this.getResult();
    },
    /*点击跳转*/
    detail(item) {
      this.$router.push({
        name: "item",
        query: { sortId:item.uuid,inputVal:this.$route.query.inputVal},
      });
    },
    /*分类接口*/
    getDbklist() {
      getItemTree().then((res) => {
        this.barData = res.data.children;
      });
    },
    /*切换分页*/
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo;
      this.getResult();
    },
    /*列表接口*/
    getResult(params) {
      search(this.uuid,{query: this.name,pageNo: this.pageNo,pageSize: 20})
      .then((res) => {
        this.resuleData = res.items.list;
        this.page = res.items;
      });
    },
    higiText(text) {
      const name = this.title;
      if (name && text.includes(name)) {
        const textArr = text.split(name);
        return `${textArr[0]}<span style="color: #ff5252;text-decoration:underline">${name}</span>${textArr[1]}`;
      }
      return text;
    },
    toDbkSearch(){
      if(this.name && this.name.trim()){
        this.$router.push({path: "/dbkView",query: { inputVal: this.name.trim() }});
      }else{
        this.$message.error("请输入检索词！");
      }

    }
  },
};
</script>

<style scoped>
/*header*/
.dbkHeader {
  width: 100%;
  height: 360px;
  background-image: url("../../assets/images/dbk/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.dbkcont {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.54);
}
.dbklogo img {
/*  width: 162px;
  height: 63px;*/
  padding-top: 90px;
  cursor: pointer;
}
.term {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
.termLeft {
  width: 838px;
  height: 58px;
  background: rgba(255, 255, 255, 0.17);
  border: 1px solid rgba(255, 255, 255, 0.63);
  border-radius: 29px;
  display: flex;
  align-items: center;
  padding: 0px 30px 0px 25px;
  box-sizing: border-box;
  margin-right: 10px;
}
.termLeft input {
  flex: 1;
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  color: #fefefe;
}
.termLeft img {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.termRight {
  width: 114px;
  height: 58px;
  background: rgba(255, 255, 255, 0.28);
  border-radius: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}
.termRight img {
  width: 30px;
  height: 32px;
  padding-right: 5px;
}
a {
  text-decoration: none;
}
.searchList {
  background: #f5f6f5 !important;
  min-height: 100%;
}
.dbkbox {
  min-height: 100vh;
  height: auto;
  background: #eff5f2;
  /*  margin: 0 auto -116px;*/
}
.boxcont {
  min-height: 100vh;
}
.width1100 {
  width: 1100px;
  margin: auto;
  position: relative;
}
.antistop {
}
.default {
  position: absolute;
  background-image: url("../../assets/images/fenlei.png");
  background-size: 100% 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.result {
  text-align: left;
  padding-top: 17px;
}
.result ul li {
  background: #ffffff;
  padding: 10px;
  display: flex;
  border-radius: 4px;
  margin-bottom: 16px;
  cursor: pointer;
}
.result ul li:hover {
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.21);
  transition: 0.3s;
}
.result .info {
  padding-left: 22px;
}
.result a {
  display: inline-block;
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  padding: 7px 0px 15px 0px;
  /* text-decoration: underline;*/
}
.result .p2 {
  color: #333333;
  font-size: 16px;
  line-height: 30px;
  padding-right: 30px;
}
.result .resultImg {
  width: 180px;
  height: 135px;
  flex-shrink: 0;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.result .resultImg img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.ellipsis3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.arranage {
  padding-top: 20px;
}
.arranage ul {
  display: flex;
  padding-top: 20px;
  flex-wrap: wrap;
}
.arranage ul li {
  width: 260px;
  margin-right: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 18px;
}
.arranage .arrImg {
  min-height: 150px;
  position: relative;
  display: flex;
  align-items: center;
}
.arranage ul li:nth-child(4n) {
  margin-right: 0;
}
.arranage .wahead {
  padding-top: 15px;
  display: block;
  font-size: 18px;
  color: #333333;
  text-align: left;
  cursor: pointer;
}
.arranage p span {
  width: 4px;
  height: 16px;
  background: #ff5252;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
}
.arranage p b {
  color: #333333;
  padding-left: 13px;
}
.arranage p {
  font-size: 14px;
  color: #666666;
  line-height: 26px;
  text-align: left;
}
.arranage img {
  width: 100%;
}
.arranage >>> .vue-waterfall-column > div {
  background: #ffffff;
  margin-bottom: 20px;
  padding-bottom: 25px;
  overflow: hidden;
  border-radius: 4px;
  margin-right: 20px;
  cursor: pointer;
}
.arranage >>> .vue-waterfall-column > div:hover {
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.28);
  transition: 0.3s;
}
.watext {
  padding: 15px 18px 0px 18px;
  box-sizing: border-box;
  max-height: 90px;
}
/*回到顶部*/
/*.backTop{
  position: sticky;
  width: 48px;
  height: 46px;
  background: #999999;
  border-right: 3px;
  margin-left: 103%;
  bottom: 200px;
  cursor: pointer;
}*/
/*菜单*/
.record {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 23px 0px 15px 0px;
  border-bottom: 1px solid #e6e6e6;
}
.record span {
  color: #999999;
  font-size: 14px;
}
.overall {
  display: flex;
  cursor: pointer;
}
.overall p {
  width: 20px;
  height: 18px;
}
.overall .tadit {
  margin-right: 18px;
  background-image: url("../../assets/images/dbk/heng.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.overall .liken {
  background-image: url("../../assets/images/dbk/zong.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.overall .cross {
  background-image: url("../../assets/images/dbk/heng1.png");
  background-size: 100% 100%;
}
.overall .fore {
  background-image: url("../../assets/images/dbk/zong2.png");
  background-size: 100% 100%;
}
.carte ul {
  display: flex;
  align-items: center;
  padding: 20px 0px;
  border-bottom: 1px solid #e6e6e6;
}
.carte ul li {
  color: #2464a9;
  font-size: 14px;
  margin-right: 30px;

  cursor: pointer;
}
.carte ul li:hover{
  text-decoration: underline;
}
.carte ul p {
  font-size: 20px;
  font-weight: 800;
  padding-right: 35px;
}
.carte ul .active {
  color: #ff5252;
}
/**/
</style>
<style>
/*分页*/
.boxcont .el-pagination {
  padding: 50px 0px;
}
.boxcont .el-pager li {
  width: 36px;
  height: 36px;
  line-height: 36px;
}
.boxcont .el-pagination.is-background .btn-next,
.boxcont .el-pagination.is-background .btn-prev,
.boxcont .el-pagination.is-background .el-pager li {
  border: 1px solid #e6e6e6 !important;
  background: #ffffff !important;
}
.boxcont .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ff5252 !important;
  border: 1px solid #ff5252 !important;
}
.boxcont .el-pagination.is-background .el-pager li:not(.disabled):hover {
  background: #ff5252 !important;
  color: #ffffff !important;
}
.boxcont .el-pagination.is-background .btn-next,
.boxcont .el-pagination.is-background .btn-prev,
.boxcont .el-pagination.is-background .el-pager li {
  width: 36px;
  height: 36px;
  line-height: 36px;
}
.boxcont .el-pagination__editor.el-input .el-input__inner,
.boxcont .el-input--mini .el-input__inner,
.boxcont .el-pagination button,
.boxcont .el-pagination span:not([class*="suffix"]) {
  height: 36px;
  line-height: 36px;
}
.boxcont .el-icon-arrow-right:before {
  content: url("../../assets/images/dbk/next.png");
}
.boxcont .el-icon-arrow-left:before {
  content: url("../../assets/images/dbk/last.png");
}
html,
body,
#app {
  background: #eff5f2 !important;
}
.vue-waterfall-easy {
  width: 1100px !important;
  height: 100%;
}
.vue-waterfall-easy-container > .loading {
  display: none !important;
}
.searchList .box_logo,.searchList #two{
  display: none;
}
</style>
